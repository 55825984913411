import React from 'react';
import Top from "./parts/Top";
import Plan from "./parts/Plan";
import mixpanel from 'mixpanel-browser';
import {useNavigate} from "react-router-dom";
import InfoPaywall from "./parts/InfoPaywall";
import Payment from "./parts/Payment";
import {getItem, getSurveyData, gtagEvent, setStep} from "./utils/lib";
import RiveLoad from "./parts/RiveLoad";
import {Trans, useTranslation} from "react-i18next";
import {LanguageEnum} from "./_business/LanguageEnum";
import {GoogleEventEnum} from "./_business/GoogleEventEnum";


function Timer({id, time, className}) {
  return (
    <strong className={className} id={id}>{Math.floor(time / 60)}:{time % 60 < 10 ? 0 : ''}{time % 60}</strong>
  );
}


function TimerPanel({count, onTime}) {
  const {t} = useTranslation('common', {keyPrefix: 'paywall'});

  let timer;
  const [time, setTime] = React.useState(count);

  React.useEffect(() => {
    timer = setInterval(() => {
      setTime(time => {
        onTime && onTime(time - 1);

        if (time <= 0) {
          mixpanel.track('time is out');
          // onClose && onClose();

          document.querySelectorAll('.reserved').forEach((item) => {
            item.style.display = 'none';
          });
          document.querySelector('.top').removeAttribute('style');

          clearInterval(timer);
        }

        return time - 1
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    if (document.getElementById('time'))
      document.querySelectorAll('.timing').forEach(function(el) {
        el.innerHTML = document.getElementById('time').innerHTML;
      });
  }, [time]);

  if (time <= 0)
    return true;

  return (
    <div className={'panel-timer'}>
      <div className={'wrap px-2 text-center'}>
        <p>
          <Trans i18nKey="expires" t={t}>
            <em>Intro offer expires in:</em> <Timer id={'time'} time={time}/>
          </Trans>
        </p>
      </div>
    </div>
  );
}


export default function Paywall({step, toAdd, nextUrl}) {
  const {t, i18n} = useTranslation();
  const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage);

  const [active, setActive] = React.useState();
  const navigate = useNavigate();
  const [timing] = React.useState(15 * 60);

  const resultList = getItem('survey_results');
  const sex =
    +resultList?.[
      surveyList.findIndex(({id}) => id === 'gender')
    ]?.[0] === 0 ? 'boy' : 'girl';

  const goalList = t('paywall.goals', {returnObjects: true});

  // const A5 = getExperiment('A5');
  const A5 = {value: 'A'};

  // const experiment = getExperiment('A1');
  //
  // if (['C', 'D'].includes(experiment.value)) {
  //   goalList.splice(-1);
  //   goalList.splice(5, 1);
  // }

  // const A4 = getExperiment('A4');

  // const A23 = i18n.resolvedLanguage === 'en' ? getExperiment('A23') : {value: 'A'};

  // const A27 = i18n.resolvedLanguage === 'en' ? getExperiment('A27') : {value: 'A'};

  const
    s1 = surveyList.findIndex(({id}) => id === 'target1'),
    g1 =
      window.landing.includes('masculinity')
        ? surveyList[s1].list.findIndex(({id, title}) => id === 'Masculinity' || title === 'Masculinity')
        : +resultList?.[s1]?.[0] || 0;

  const goal = goalList?.[g1] || goalList[0];

  if (!goal.title) {
    try {
      // const
      //   s2 = surveyList.findIndex(({id}) => id === 'target2'),
      //   g2 = +resultList?.[s2]?.[0];

      goal.title = surveyList[s1].list[g1]?.title || surveyList[s1].list[0]?.title; // + ' and ' + surveyList[s2].switch[g1].list[g2].title.toLowerCase();
    } catch(e) {
      console.error(e);
    }
  }

  const goalBoy = goal['boy'];
  const goalSex = goal[sex] || goalBoy;

  const advantagesList =
    i18n.exists(`paywall.info.advantages.list-${g1}`)
      ? t(`paywall.info.advantages.list-${g1}`, {returnObjects: true})
      : null;

  React.useEffect(() => {
    if (!window.localStorage.getItem('uid')) {
      navigate('/');
      return;
    }

    // window.backTimer = setInterval(() => {
      if (window.sessionStorage.getItem('success')) {
        window.sessionStorage.removeItem('success');
        navigate('/', {replace: true});
        return;
      }
    // }, 200);

    setStep(step, toAdd);
    // setExperiment(A4);

    gtagEvent(GoogleEventEnum.paywall);

    // if (window.ttq) {
    //   window.ttq.track('AddToCart', {
    //     "contents": [
    //       {
    //         "content_id": "subscription" // string. ID of the product. Example: "1077218".
    //       }
    //     ]
    //   }, {
    //     event_id: sha256('AddToCart_' + window.session_uid)
    //   });
    // }

    // return () => {
    //   clearInterval(window.backTimer);
    // };
  }, []);

  React.useEffect(() => {
    if (active) {
      window.sessionStorage.setItem(
        'price',
        JSON.stringify({
          id: active.id,
          // book: {
            currency: active.currency,
            currencySymbol: active.currencySymbol,
            full_price: active.book.full_price,
            name: active.book.name,
            price: active.book.price,
            promo: active.book.promo,
            // ...active.book,
          // }
        })
      );
    }
  }, [active]);

  //onTime={(time) => setTiming(time)}
  return (
    <>
      <TimerPanel count={timing}/>

      <div className={'wrap'}>
        <Top className={'gap-3'} style={timing ? {marginTop: '48px'} : {}}>
          <div className={'block items'}>
            <h1 className={'h2 text-center'} dangerouslySetInnerHTML={{__html: t('paywall.h1')}}/>

            <div className={'goal'}>
              <div className={'subtitle' + (goal.title.includes('<br>') ? ' compact' : '')} dangerouslySetInnerHTML={{__html: goal.title}}/>
              <div className={'row gap-0 stretch'}>
                <div className={'sad'}>
                  <b className={'tag-note notice xs mt-1'}>{t('paywall.now')}</b>
                  <img src={require(`./assets/img/paywall/goal/${A5.value === 'B' ? `${goal.id}/sad-${sex}.png` : `sad-${sex}.svg`}`)} width={139} height={120} alt={''} className={'rounded-2 w-100 aspect-2'}/>
                  <strong className={'small'} dangerouslySetInnerHTML={{__html: goalSex.sad || goalBoy.sad}}/>
                </div>
                <div className={'happy'}>
                  <b className={'tag-note notice xs mt-1'} style={{color: 'var(--bg-100)', backgroundColor: 'var(--white-100)'}}>{t('paywall.in3Months')}</b>
                  <img src={require(`./assets/img/paywall/goal/${A5.value === 'B' ? `${goal.id}/happy-${sex}.png` : `happy-${sex}.svg`}`)} width={139} height={120} alt={''} className={'rounded-2 w-100 aspect-2'}/>
                  <strong className={'small'} dangerouslySetInnerHTML={{__html: goalSex.happy || goalBoy.happy}}/>
                </div>
              </div>
              <div className={'info'}>
                <p className={'notice xs'}>{t('paywall.note')}</p>
                <div className={'row'}>
                  <p className={'notice xs'} dangerouslySetInnerHTML={{__html: t('paywall.joined1')}}/>
                  <p className={'notice xs'} dangerouslySetInnerHTML={{__html: t('paywall.joined2')}}/>
                </div>

                {
                  timing > 0 &&
                  <>
                    <strong className={'reserved'}>{t('paywall.reserved.title')}</strong>
                    <p className={'reserved small-sm'} dangerouslySetInnerHTML={{__html: t('paywall.reserved.time')}}/>
                    <p className={'reserved note'} style={{color: '#fff'}}>
                      <Trans i18nKey="paywall.reserved.remaining" t={t}>
                        TIME REMAINING: <Timer time={timing} className={'timing gold'}/>
                      </Trans>
                    </p>
                  </>
                }
              </div>
            </div>

            <RiveLoad width={350} height={238} src={`graphs${['de'].includes(i18n.resolvedLanguage) ? '_' + i18n.resolvedLanguage : ''}.riv`} stateMachines="graph" inputName={'localisation_id'} value={LanguageEnum[i18n.resolvedLanguage]}/>

            <div className={'block items'}>
              <strong dangerouslySetInnerHTML={{__html: t('paywall.plan.title')}}/>
              <div className={'paragraph'}>
                {
                  t('paywall.plan.list', {returnObjects: true}).map((item, i) =>
                    <p key={i} className={'notice small row'} style={{gap: '0.75rem'}}>
                      <img src={require('./assets/img/icon/star2.svg').default} alt={'★'}/>
                      {item}
                    </p>
                  )
                }
              </div>
            </div>

            <h3 className={'text-center'} dangerouslySetInnerHTML={{__html: t('paywall.plan.header')}}/>
            {
              // A4.value === 'A' &&
              <div className={'w-100 text-center small'}
                   style={{backgroundColor: '#E06072', padding: '0.75rem 1.5rem', fontWeight: 500, marginBottom: '10px', width: 'calc(100% + 1.5rem)'}}>
                <Trans i18nKey="paywall.plan.offerEnds" t={t}>
                  🕓 Once you close offer, it’s gone <Timer time={timing} className={'timing'}/> min
                </Trans>
              </div>
            }
            <Plan onChange={item => setActive(item)} />
          </div>

          <InfoPaywall advantagesList={advantagesList}/>
          <Payment data={active} nextUrl={nextUrl}/>
        </Top>
      </div>
    </>
  )
}
