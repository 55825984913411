import mixpanel from 'mixpanel-browser';
import {ExperimentEnum} from "../_business/ExperimentEnum";
import {SurveyEnum} from "../_business/SurveyEnum";

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */

// let ix = 0;
export function mergeDeep(target, ...sources) {
  // console.log('mergeDeep', target, sources)
  // ix++; if (ix > 100) return target;

  if (!sources.length)
    return target;

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key])
          Object.assign(target, { [key]: {} });

        mergeDeep(target[key], source[key]);
      } else
        Object.assign(target, { [key]: source[key] });
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 * Up timer.
 * @param callback
 * @param timeout
 */
export function upTimer(callback, timeout = 300) {
  if (window.upTimer) {
    clearTimeout(window.upTimer);
    delete window.upTimer;
  }

  window.upTimer = setTimeout(callback, timeout);
}

/**
 * Copy object.
 * @param obj
 */
export function cloneDeep(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Is IOS.
 */
export function isIos() {
  const ua = navigator.userAgent.toLowerCase();
  return /i(Phone|Pad|Pod)/i.test(ua) || /^((?!chrome|android).)*safari/.test(ua);
}

/**
 * First letter lowercase.
 * @param string
 */
export function toFirstLower(string) {
  return string.replace(/(?:^|\s)\S/g, (a) => {
    return a.toLowerCase();
  });
}

/**
 * First letter upcase.
 * @param str
 */
export function toFirstUp(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Interval fetching.
 * @param url
 * @param data
 * @param callback
 * @param method
 */
export const dataFetch = async (url, data, callback, method = 'POST') => {

  const repeatCallback = (error, message) => {
    setTimeout(() => {
      mixpanel.track(error, {message});
      dataFetch(url, data, callback, method);
    }, 1000);
  };

  try {
    const response = await fetch(url, method === 'POST' ? {
      method,
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    } : undefined);

    if (!response?.ok) {
      console.error(response);
      repeatCallback('Server Error', `${response?.status} ${response?.statusText}`);
      return;
    }

    const dataJson = await response.json();

    callback && callback(dataJson);
  } catch (err) {
    console.error(err);
    repeatCallback('Error', `${err?.message}`);
  }
}

/**
 * First letter lowercase.
 * @param name
 * @param defaultValue
 */
export function getItem(name, defaultValue = [], storage = window.localStorage) {
  try {
    return (JSON.parse(storage.getItem(name)) || defaultValue);
  } catch (e) {
    console.error(e);
  }

  return defaultValue;
}

/**
 * Set step.
 * @param data
 * @param toAdd
 * @param force
 */
export function setStep(data, toAdd = true, force = false) {
  if (toAdd) {
    const resultList = getItem('survey_results');
    data.step += resultList.length - 1;
  }

  if (force || data.step > 35 && window.sessionStorage.getItem('summarize'))
    data.step++;

  console.log(data);
  mixpanel.track_pageview(data);

  // const callback = () => {
  //   console.log(data);
  //   mixpanel.track_pageview(data);
  // }
  //
  // if (window.loaderDone)
  //   callback();
  // else
  //   setTimeout(callback, 3500);
}

/**
 * Set session.
 * @param props
 */
export function setSession(props) {
  window.sessionStorage.setItem('session_uid', window.session_uid);

  dataFetch(
    process.env.REACT_APP_API_URI + 'web-sessions',
    {
      ...props,
      session_uid: window.session_uid,
      version: process.env.REACT_APP_VERSION,
      quiz: window.quiz,
      landing: window.landing,
    },
    (response) => {
      // response.country_code = 'UG'
      if (response.country_code) {
        window.sessionStorage.setItem('country_code', response.country_code);
        // window.updateCountry && window.updateCountry(response.country_code);
      }

      // response.ask_consent = true;

      // setTimeout(() => {
        window.sessionStorage.setItem('ask_consent', response.ask_consent ? '2' : '1');
        window.askConsent && window.askConsent(response.ask_consent);
      // }, 4000)
    }
  );
}

/**
 * Get survey data.
 * @param surveyList
 */
export function getSurveyData(surveyList, lang) {
  // const target1 = surveyList.find(({id}) => id === 'target1');
  //
  // if (target1) {
  //   target1.experiment = getExperiment('A1');
  //
  //   if (['B', 'D'].includes(target1.experiment.value)) {
  //     target1.name = 'What motivates you the most?';
  //     target1.description = 'Let’s find out the main area to focus on.';
  //
  //     target1.list = [
  //       { "icon1": "💬", "title": "Communication", "subtitle": "Speak clearly and confidently" },
  //       { "icon1": "🎤", "title": "Relationships", "subtitle": "Get a happy relationship with healthy communication" },
  //       { "icon1": "🚀", "title": "Career", "subtitle": "Stand out and get noticed in the workplace" },
  //       { "icon1": "🥰️", "title": "Singing", "subtitle": "Sing better and own the spotlight" },
  //       { "icon1": "💪", "title": "Masculinity", "subtitle": "Get a masculine voice and feel confident" },
  //       { "icon1": "🌸", "title": "Feminity", "subtitle": "Feminize your voice and feel confident" },
  //       { "icon1": "🎯", "title": "Accent", "subtitle": "Improve your pronunciation" },
  //       { "icon1": "👨‍⚕️", "title": "Voice health", "subtitle": "Speak freely, with no strain" },
  //       { "icon1": "💬", "title": "Other", "subtitle": "Speak clearly and confidently" }
  //     ];
  //   }
  //
  //   if (['C', 'D'].includes(target1.experiment.value) && target1.list.length >= 9) {
  //     target1.list.splice(-2);
  //     target1.list.splice(5, 1);
  //
  //     for (const survey of surveyList) {
  //       if (survey.switch && survey.target === 'target1' && survey.switch.length >= 9) {
  //         survey.switch.splice(-2);
  //         survey.switch.splice(5, 1);
  //       }
  //     }
  //   }
  // }

  if (lang === 'en') {
    //   const A13 = getExperiment('A13');
    //
    //   if (A13.value === 'B') {
    //     const i = surveyList.findIndex(({type}) => type === 'progress');
    //
    //     surveyList.splice(i + 1, 0, ...[
    //       {
    //         "name": "Are you<br>a native speaker?",
    //         "type": "boolean"
    //       },
    //       {
    //         "name": "How would you describe<br>your voice?",
    //         "type": "inline",
    //         "multiple": true,
    //         "optional": true,
    //         "list": [
    //           { "title": "Melodic" },
    //           { "title": "Soft" },
    //           { "title": "Confident" },
    //           { "title": "Harsh" },
    //           { "title": "Deep" },
    //           { "title": "Sexy" },
    //           { "title": "Strict" },
    //           { "title": "Monotonous" },
    //           { "title": "Nasal" },
    //         ]
    //       },
    //       {
    //         "name": "90% of people describe<br>their voice wrongly",
    //         "description": "This is due to 'voice confrontation’. Individuals perceive their voice differently than how it actually sounds to others. Vocal Image AI can provide a clearer, more accurate representation of your voice.",
    //         "image": "describe-voice.png",
    //         "type": "info"
    //       },
    //     ]);
    //   }

    // const A14 = getExperiment('A14');
    //
    // if (A14.value === 'B') {
    //   const i = surveyList.findIndex(({id}) => id === 'thanks');
    //
    //   surveyList.splice(i, 0, ...[
    //     {
    //       "name": "Have you experienced<br>any of these issues?",
    //       "type": "start-line",
    //       "optional": true,
    //       "list": [
    //         { "title": "Difficulty in producing sounds" },
    //         { "title": "Loss of voice" },
    //         { "title": "Speech disruptions" },
    //         { "title": "Slow speech" },
    //         { "title": "Rapid speech" },
    //         { "title": "Nothing above" },
    //       ]
    //     },
    //   ]);
    // }
  }

  // const A15 = getExperiment('A15');
  //
  // if (A15.value === 'B') {
  //   const i = surveyList.findIndex(({id}) => id === 'describe-voice');
  //
  //   surveyList.splice(1, 0, ...[
  //     surveyList[i],
  //     surveyList[i + 1],
  //     surveyList[i - 1],
  //   ]);
  //
  //   surveyList.splice(i - 1 + 3, 3);
  // }

  return surveyList;
}

/**
 * Get experiment.
 * @param name
 */
export function getExperiment(name) {
  if (!ExperimentEnum[name])
    return null;

  const experiment = {name, value: window.sessionStorage.getItem(name)};

  if (process.env.REACT_APP_DEBUG) {
    let experiments = document.querySelector('#experiments');

    if (!experiments) {
      experiments = document.createElement('div');
      experiments.setAttribute('id', 'experiments');
      experiments.setAttribute('style', 'position: fixed; z-index: 100; bottom: 5px; left: 5px; line-height: 1;');
      document.body.append(experiments);
    }

    if (!document.querySelector(`#exp_${name}`)) {
      const buttons = [];

      const addButton = (name, item) => {
        const selected = 'class="gold" style="font-weight: bold; cursor: default"';
        const onclick = (name, item) => {
          return `onclick="window.sessionStorage.setItem('${name}', '${item}'); window.location.reload()"`;
        };

        buttons.push(`<em role="button" data-item="${item}" ${experiment.value === item ? selected : onclick(name, item)}>${item}</em>`);
      };

      if (Array.isArray(ExperimentEnum[name])) {
        for (const item of ExperimentEnum[name])
          addButton(name, item);
      } else {
        for (const item in ExperimentEnum[name])
          addButton(name, item);
      }

      experiments.innerHTML += `<div id="exp_${name}">${name}: ${buttons.join(', ')}</div>`;
    }
  }

  if (experiment.value)
    return experiment;

  if (Array.isArray(ExperimentEnum[name]))
    experiment.value = ExperimentEnum[name][
      Math.floor(Math.random() * ExperimentEnum[name].length)
    ];
  else {
    const arr = [];

    for (const key in ExperimentEnum[name])
      arr.push(key);

    experiment.value = arr.find((el, i) => {
      const sum = arr.slice(0, i + 1).reduce((acc, el) => {
        return acc + ExperimentEnum[name][el] / 100;
      }, 0);

      return Math.random() < sum;
    });
  }

  if (process.env.REACT_APP_DEBUG) {
    const item = document.querySelector(`#exp_${name} [data-item=${experiment.value}]`);

    if (item) {
      item.removeAttribute('onclick');
      item.setAttribute('class', 'gold');
      item.setAttribute('style', 'font-weight: bold; cursor: default');
    }
  }

  window.sessionStorage.setItem(name, experiment.value);

  setExperiment(experiment);

  return experiment;
}

/**
 * Set experiment.
 * @param props
 */
export function setExperiment({name, value}) {
  console.log('experiment', {[name]: value})

  // mixpanel.track('experiment', {name, value});

  // mixpanel.people.set({
  //   [name]: value
  // });

  mixpanel.register({
    [name]: value
  });
}

export function getResults(surveyList, resultList) {
  const results = [];

  try {
    // resultList.map((list, i) => {
    //   let data = {list};
    //
    //   const getSurveyByIndex = (index, survey) => {
    //     if (!survey)
    //       survey = {...surveyList[index]};
    //
    //     if (survey?.switch) {
    //       const sIndex =
    //         survey.target
    //           ? surveyList.findIndex(item => item.id === survey.target)
    //           : index - 1;
    //       let rIndex;
    //
    //       rIndex = resultList[sIndex]?.[0];
    //
    //       if (survey.targetIndex) {
    //         rIndex = survey.switch.findIndex(item => item.targetIndex === rIndex);
    //
    //         if (rIndex < 0)
    //           rIndex = 0;
    //       }
    //
    //       survey = survey.switch[rIndex];
    //
    //       data = {
    //         switch: {
    //           [rIndex]: data
    //         }
    //       };
    //
    //       if (survey?.switch)
    //         survey = getSurveyByIndex(index, survey);
    //     }
    //
    //     return survey;
    //   };
    //
    //   let survey = getSurveyByIndex(i);
    //   // const survey = surveyList[i];
    //
    //   if (
    //     !survey ||
    //     [SurveyEnum.info, SurveyEnum.infoReverse, SurveyEnum.progress].includes(surveyList[i]?.type || survey.type)
    //   )
    //     data = {};
    //
    //   results.push(data);
    //
    //   return true;
    // });




    // FIXME: dupe in Survey
    const getSurveyByIndex = (index, survey) => {
      if (!survey)
        survey = {...surveyList[index]};

      if (survey?.switch) {
        const sIndex =
          survey.target
            ? surveyList.findIndex(item => item.id === survey.target)
            : index - 1;
        let rIndex;

        rIndex = resultList[sIndex]?.[0];

        if (survey.targetIndex) {
          rIndex = survey.switch.findIndex(item => item.targetIndex === rIndex);

          if (rIndex < 0)
            rIndex = 0;
        }

        survey = survey.switch[rIndex];

        if (survey?.switch)
          survey = getSurveyByIndex(index, survey);
      }

      return survey;
    };

    const
      s1 = surveyList.findIndex(({id}) => id === 'target1'),
      g1 =
        window.landing.includes('masculinity')
          ? surveyList[s1].list.findIndex(({id, title}) => id === 'Masculinity' || title === 'Masculinity')
          : +resultList?.[s1]?.[0] || 0;

    resultList.map((resList, i) => {
      let survey = getSurveyByIndex(i);

      if (surveyList[i]?.type === SurveyEnum.pic && !survey?.id)
        survey.id = survey.title.replace(/ /g, '-');

      // const id = survey?.id || surveyList[i]?.id;

      if (
        // !id ||
        [SurveyEnum.info, SurveyEnum.infoReverse, SurveyEnum.progress].includes(surveyList[i]?.type || survey.type)
      )
        return true;

      if (survey.type === SurveyEnum.boolean && !survey.list)
        survey.list = [
          {"title": "No"},
          {"title": "Yes"}
        ];

      const list = [];

      for (const j of resList || []) {
        // let title = survey.list?.[j]?.id || survey.list?.[j]?.title || surveyList[i]?.id || survey.id || survey.name;
        let title = survey.list?.[j]?.title || surveyList[i]?.id;

        if (title) {
          if (Array.isArray(title))
            title = title[g1] || title[0];

          title = title.replace(/<br>/g, ' ');
        }

        list.push(title);
      }

      results.push({id: survey.id, name: survey.name, title: survey.title, list});

      return true;
    });

    // console.log(data.resultList); return true;
  } catch (ex) {
    console.error(ex);

    if (process.env.REACT_APP_DEBUG)
      throw new Error(ex);
  }

  return results;
}

export const scrollIntoViewWithOffset = (selector, offset) => {
  // document.getElementById('payment').scrollIntoView({block: "start", behavior: 'smooth'})
  window.scrollTo({
    behavior: 'smooth',
    top:
      document.querySelector(selector).getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  })
};

export function gtagEvent(eIndex, params) {
  if (window.gtagConfig?.[eIndex])
    window.gtag(
      'event',
      'conversion', {
        'send_to': `${window.gtagConfig[0]}/${window.gtagConfig[eIndex]}`,
        ...{
          ...params,
          'transaction_id': window.session_uid,
        }
      }
    )
  else
    console.error('gtagConfig implementation');

  if (window.gtagConfigOur?.[eIndex])
    window.gtag(
      'event',
      'conversion', {
        'send_to': `${window.gtagConfigOur[0]}/${window.gtagConfigOur[eIndex]}`,
        ...{
          ...params,
          'transaction_id': window.session_uid,
        }
      }
    )
  else
    console.error('gtagConfigOur implementation');
}
